import { configureStore } from '@reduxjs/toolkit'
import { settingSlice } from './features/Setting/settingSlice'
import {  productById, stockProductSlice } from './features/Product/productSlice'
import { registrationSlice } from './features/Auth/registrationSlice'
import { basket } from './features/Basket/basketSlice'
import { orderSlice } from './features/Order/orderSlice'


const store = configureStore({
  reducer: {
    settingSlice: settingSlice.reducer,
    productById: productById.reducer,
    stockProducts: stockProductSlice.reducer,
    registerSlice: registrationSlice.reducer,
    basketSlice: basket.reducer,
    orderSlice: orderSlice.reducer,
  },
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store
