import { lazy } from 'react'
import { ERoutePaths, TRoutePageType } from './types'

const Product = lazy(() => import('pages/Product'))
const routesList: TRoutePageType[] = [
  {
    element: Product,
    path: ERoutePaths.Product,
    title: 'Product Page',
  },
]

export default routesList
