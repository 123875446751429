import { useState, type FC, useEffect } from 'react'
import { useLockBodyScroll } from 'hooks'
import { Link} from 'react-router-dom'
import {
  TelegramIcon,
  ViberIcon,
} from 'assets'

import styles from './Header.module.scss'
import './MobileHeader.scss'
import { useAppSelector } from 'hooks/useTypedSelector'

const Header: FC = () => {
  const userData = localStorage.getItem('userData')
  const { settingSlice } = useAppSelector(state => state)
  const [name, setName] = useState<string>(settingSlice.data.data?.name)
  const [isOpen, setOpen] = useState(false)
  function extractUsernameFromTelegramAccount(telegramAccount: string) {
    if (!telegramAccount) {
      return null
    }

    let cleanedAccount = telegramAccount.replace(/^https?:\/\/t\.me\//, '')

    cleanedAccount = cleanedAccount.replace(/^@/, '')

    return cleanedAccount
  }



  function generateViberLink(viberInfo: any) {
    if (!viberInfo) {
      return null
    }

    if (viberInfo.startsWith('http') || viberInfo.startsWith('viber')) {
      return (
        <a href={viberInfo} target='_blank'>
          <ViberIcon className='w-7' />
        </a>
      )
    }

    if (/^\+\d{2,3}/.test(viberInfo)) {
      return (
        <a href={`viber://contact?number=${encodeURIComponent(viberInfo)}`} target='_blank'>
          <ViberIcon className='w-7' />
        </a>
      )
    }

    return null
  }

  useEffect(() => {
    const name = localStorage.getItem('name')

    console.log('settingSlice.data.data?.name ', settingSlice.data.data?.name )

    console.log('name', name)
    if (name) {
      setName(name)
    }
  }, [name])

  useEffect(() => {
    const userData = localStorage.getItem('userData')

    if (userData) {
      // const parsedData = JSON.parse(userData)

    }
  }, [])

  const store_name = localStorage.getItem('store_name')


  useEffect(() => {
    if(settingSlice.data.data?.name ) {
      localStorage.setItem('store_name' ,settingSlice.data.data?.name )
    }
    }, [settingSlice.data.data?.name ])

  useLockBodyScroll(!isOpen)

  const logout = () => {
    localStorage.removeItem('userData')
    window.open('/', '_self')
  }



  return (
    <>
      <header className={styles.wrapper}>
        <div className={styles.wrapper__container}>
          <Link to='/'>
            <h1
              style={{
                minWidth: 120,
                color: 'white',
                fontWeight: '900',

                fontSize: 26,
                textTransform: 'capitalize',
              }}
            >
              { store_name || settingSlice.data.data?.name }
            </h1>
          </Link>
              <div className=' flex gap-2 items-center'>
                {settingSlice.data.data?.telegram_url && (
                  <a
                    href={`tg://resolve?domain=${extractUsernameFromTelegramAccount(
                      settingSlice.data.data?.telegram_url
                    )}`}
                    target='__blank'
                  >
                    <TelegramIcon className='w-7' />
                  </a>
                )}
                {generateViberLink(settingSlice.data.data?.viber_url)}
              </div>
        </div>
      </header>
    </>
  )
}

export default Header
