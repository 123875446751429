import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api/axiosConfig'
import { TAuth } from './type'

const initialState: TAuth = {
  data: [],
  loading: false,
  error: null,
}


export const registrationGuest = createAsyncThunk('auth/reguser/guest', async () => {
  try {
    const response = await api.get(`/v2/auth/register-guest`)


    return response.data
  } catch (err) {
    console.log(err)
    throw Error('Failed to registration guest')
  }
})

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(registrationGuest.fulfilled, (state, action) => {
        state.data = action.payload
        localStorage.setItem('userData', JSON.stringify(action.payload))
      })
  },
})

export { registrationSlice }
